<template>
  <div class="col-lg-8">
    <div class="row">
      <div class="col-12">
        <div class="dash-pro-item mb-30 dashboard-widget">
          <div class="header">
            <h4 class="title">Trocar Senha</h4>
            <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
          </div>
          <form class="login-form" @submit.prevent="trocarSenha" v-if="getAuth">
            <div class="row">
              <div class="col-md-12">Senha Atual</div>
              <div class="form-group mb-40 col-md-12">
                <label for="senha-atual"><i class="fas fa-unlock"></i></label>
                <input :type="passwordFieldType" id="senha-atual" v-model="senhaAtual" required />
                <span class="pass-type" @click="switchVisibility()"
                  ><i class="fas" :class="{ 'fa-eye': passwordFieldType == 'password', 'fa-eye-slash': passwordFieldType != 'password' }"></i
                ></span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">Nova Senha</div>
              <div class="form-group mb-10 col-md-12">
                <label for="nova-senha"><i class="fas fa-lock"></i></label>
                <input :type="passwordFieldType2" id="nova-senha" v-model="novaSenha" required />
                <span class="pass-type" @click="switchVisibility2()"
                  ><i class="fas" :class="{ 'fa-eye': passwordFieldType2 == 'password', 'fa-eye-slash': passwordFieldType2 != 'password' }"></i
                ></span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">Confirmação Nova Senha</div>
              <div class="form-group mb-30 col-md-12">
                <label for="conf-nova-senha"><i class="fas fa-lock"></i></label>
                <input :type="passwordFieldType3" id="conf-nova-senha" v-model="confNovaSenha" required />
                <span class="pass-type" @click="switchVisibility3()"
                  ><i class="fas" :class="{ 'fa-eye': passwordFieldType3 == 'password', 'fa-eye-slash': passwordFieldType3 != 'password' }"></i
                ></span>
              </div>
            </div>
            <div class="row" v-if="errors.length > 0">
              <div class="col-12">
                <div class="alert alert-danger" role="alert">
                  <ol class="mb-0">
                    <li class="mb-0" v-for="erro in errors" :key="erro">
                      {{ erro }}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <button type="submit" class="custom-button">
                <i class="fa fa-spin fa-spinner" v-if="loading == true"></i>
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import http from '@/service/http/http';
import moment from 'moment';
import Notification from '@/mixins/notification';
moment.locale('pt-br');
export default {
  name: 'trocar-senha',
  data() {
    return {
      identityId: '',
      senhaAtual: '',
      novaSenha: '',
      confNovaSenha: '',
      passwordFieldType: 'password',
      passwordFieldType2: 'password',
      passwordFieldType3: 'password',
      errors: [],
      loading: false,
    };
  },
  mixins: [Notification],
  computed: {
    ...mapGetters(['getAuth']),
  },
  methods: {
    ...mapActions(['info']),
    async trocarSenha() {
      this.errors = [];
      if (this.novaSenha != this.confNovaSenha) {
        this.showToast({ title: 'As senhas não conferem', type: 'warning' });
        return false;
      }

      this.loading = true;
      const payload = {
        identityId: this.identityId,
        senhaAtual: this.senhaAtual,
        novaSenha: this.novaSenha,
      };
      try {
        await http.put(`usuario/${this.identityId}/trocarSenha`, payload);
        this.loading = false;
        this.showToast({ title: 'Sennha alterada com sucesso' });
        this.$router.push({ name: 'perfil' });
      } catch (e) {
        this.loading = false;

        if (e.response.status == 422) {
          const errors = e.response.data.Errors;
          for (var key in errors) {
            if (key == 'PasswordMismatch') {
              this.errors.push('Senha atual está incorreta');
            } else {
              this.errors.push(errors[key]);
            }
          }
        } else {
          this.showAlert({
            type: 'error',
            title: 'ATENÇÃO',
            message: 'Erro ao alterar senha',
          });
        }
      }

      this.loading = false;
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchVisibility2() {
      this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password';
    },
    switchVisibility3() {
      this.passwordFieldType3 = this.passwordFieldType3 === 'password' ? 'text' : 'password';
    },
  },

  async created() {
    this.identityId = this.getAuth.uid;
  },
};
</script>

<style scoped>
.login-form .form-group input,
select {
  padding-left: 40px;
}
</style>
